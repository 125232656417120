<template>
    <div class="pdf">
        <iframe></iframe>
    </div>
</template>
<script>
import pdf from 'vue-pdf';
export default {
    name: 'Pdf',
    components: {
        pdf
    },
    data() {
        return {
            pdfUrl: 'https://oss.imttech.cn/application/2020-12-03-20-29-54bAHpXYRj.pdf'
        };
    },
    created() {
        if (this.$route.query.file) {
            this.pdfUrl = this.$route.query.file;
        }
    }
};
</script>
<style lang="scss" scoped>
.pdf {
    iframe {
        width: 100%;
        height: 100vh;
        border: 0;
    }
}
</style>
